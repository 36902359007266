import * as Sentry from '@sentry/browser';
import { ServerEventsClient } from '@servicestack/client';
import 'Assets/sound/ding.wav';
import UserProvider from 'Components/UserProvider';
import eventEmitter from 'Services/events';

const channels = ['home'];

let networkError = false;

/** @type {ServerEventsClient} */
let seClient = null; //eslint-disable-line

const init = () => {
  seClient = new ServerEventsClient("/", channels, {
    handlers: {
      GeneralMessage: (msg) => {
        eventEmitter.emit('general-message-received', msg);
      },

      NotificationReceivedMessage: (msg) => {
        eventEmitter.emit('notification-received', msg);
      },

      CurrencyProcessingCompleteMessage: (msg) => {
        eventEmitter.emit('currency-processing-complete', msg);
      },

      DispatcherQueriedMessage: (msg) => {
        eventEmitter.emit('dispatcher-started-investigation', msg);
      },

      UserUpdatedMessage: async () => {
        eventEmitter.emit('user-updated');
      },
      InvestigationUpdate: (msg) => {
        eventEmitter.emit('investigation-update', msg);
      },

      UserSuspendedMessage: () => {
        UserProvider.instance.logOff('Your account has been suspended, please contact your administrator');
      },

      NewSessionMessage: () => {
        UserProvider.instance.logOff('Session expired', true);
      },
      ConversationResponse: (conversation) => {
        eventEmitter.emit('conversation-response', conversation);
      },

      MessageResponse: (msg) => {
        eventEmitter.emit('message-response', msg);
      },
      StateHostStatusChanged: (msg) => {
        console.log(msg);
        eventEmitter.emit('state-host-available', msg);
      },
      onConnect: (args) => {
        const secretKey = args?.secretKey;
        if (args?.userId) {
          UserProvider.instance.logOffUserOnInvalidSecretKey(secretKey);
        }
        if (networkError) {
          eventEmitter.emit('network-available', true);
          networkError = false;
        }
      },

    },

    onException: err => {
      Sentry.addBreadcrumb({
        message: 'SSE client exception',
        level: Sentry.Severity.Warning,
        data: err
      });
    },
    onReconnect: (arg) => {
      const networkErrorMessage = 'Failed to fetch';
      const isNetworkUnavailable = ((arg && arg.message && arg.message === networkErrorMessage));

      if (!networkError && isNetworkUnavailable) {
        eventEmitter.emit('network-available', false);
        networkError = true;
      }

      Sentry.addBreadcrumb({
        message: 'SSE client reconnecting',
        level: Sentry.Severity.Warning,
        data: arg
      });
    }
  }).start();
};

// Try to unregister before closing the browser
if (navigator.sendBeacon) {
  window.addEventListener('unload', () => {
    navigator.sendBeacon(seClient.connectionInfo.unRegisterUrl);
  });
}

export { init as default, seClient };
